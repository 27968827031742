import {
  Box,
  Collapse,
  FormControl,
  FormControlProps,
  FormDescription,
  FormHelperText,
  FormLabel,
} from '@plugsurfing/plugsurfing-design';
import { CdTooltip } from 'components/design-elements';
import { memo, ReactNode } from 'react';

export interface CdInputGroupProps extends Omit<FormControlProps, 'label'> {
  label?: ReactNode;
  labelId?: string;
  description?: string;
  localization?: boolean;
  topRight?: ReactNode;
  tooltip?: string;
  error?: string;
  wordLimit?: number;
  textLength?: number;
  labelRightElement?: ReactNode;
}

const CdInputGroup = memo((props: CdInputGroupProps) => {
  const {
    topRight,
    label,
    labelId,
    children,
    tooltip,
    localization,
    description,
    error,
    wordLimit,
    textLength,
    labelRightElement,
    ...formControlProps
  } = props;
  const rightIcon = localization ? 'World' : undefined;
  const showFormLabel = label !== undefined;
  const tooltipElement = (bottom: boolean) =>
    tooltip === undefined ? undefined : (
      <CdTooltip
        label={tooltip}
        placement="top-start"
        iconProps={{
          ml: 'component.3xs',
          verticalAlign: bottom ? 'bottom' : 'middle',
          color: formControlProps.isDisabled ? 'disabled.disabled-secondary' : undefined,
        }}
      />
    );

  return (
    <FormControl width="unset" size="S" {...formControlProps} position="relative">
      {showFormLabel ? (
        <FormLabel rightIcon={rightIcon} mr="component.4xs" id={labelId}>
          {label}
          {tooltipElement(true)}
          {labelRightElement && <Box ml="auto">{labelRightElement}</Box>}
        </FormLabel>
      ) : undefined}
      {description !== undefined && <FormDescription>{description}</FormDescription>}
      {children}
      {showFormLabel ? undefined : tooltipElement(false)}
      <Collapse animateOpacity in={!!(typeof error === 'string' || wordLimit)}>
        <FormHelperText maxTextLength={wordLimit} textLength={textLength}>
          {error}
        </FormHelperText>
      </Collapse>
      {topRight !== undefined && (
        <Box position="absolute" top={0} right={0}>
          {topRight}
        </Box>
      )}
    </FormControl>
  );
});

export default CdInputGroup;
