import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { useDispatch } from 'react-redux';
import { createEpicMiddleware } from 'redux-observable';
import * as chargingKeyActions from './charging-keys/actions';
import * as chargingStationActions from './charging-stations/actions';
import * as electricVehiclesActions from './electric-vehicles/actions';
import * as entitiesActions from './entities/actions';
import rootEpic from './epics';
import * as firmwareActions from './firmwares/actions';
import * as invoicesActions from './invoices/actions';
import * as mapActions from './map/actions';
import * as schemasActions from './objects-schemas/actions';
import * as organizationOrdersActions from './organization-orders/actions';
import * as organizationActions from './organizations/actions';
import reducers, { RootState } from './reducers';
import { restifyAction } from './rest/reducers';
import { cdmApi } from './slices/cdmApi';
import * as subscriptionActionsV2 from './subscriptionsV2/actions';
import * as userGroupActions from './user-groups/actions';
import * as userActions from './users/actions';

// exported for testing
export const initStoreWithPreloadedState = (preloadedState: Partial<RootState>) => {
  const epicMiddleware = createEpicMiddleware();

  const storeLocal = configureStore({
    reducer: reducers,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).concat(epicMiddleware, cdmApi.middleware),
  });

  epicMiddleware.run(rootEpic);

  return storeLocal;
};

export const store = initStoreWithPreloadedState({});
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

restifyAction(organizationActions.fetchAssociatedCpos, {
  key: organizationId => organizationId,
  cacheResult: true,
});
restifyAction(organizationActions.fetchAssociatedEmps, {
  key: organizationId => organizationId,
  cacheResult: true,
});
restifyAction(organizationActions.fetchModules, {
  key: () => '',
  cacheResult: true,
});
restifyAction(organizationActions.fetchSupportedCurrencies, {
  key: organizationId => organizationId,
  cacheResult: false,
});
restifyAction(organizationActions.fetchEvseMapping, {
  key: organizationId => organizationId,
  cacheResult: true,
});
restifyAction(organizationActions.fetchOperationStatus, {
  key: organization => organization.id,
  cacheResult: false,
});
restifyAction(organizationActions.fetchPublishingChannels, {
  key: organizationId => organizationId,
  cacheResult: true,
});
restifyAction(organizationActions.fetchAdHocConfig, {
  key: organizationId => organizationId,
  cacheResult: true,
});
restifyAction(organizationActions.fetchTemplates, {
  key: organizationId => organizationId,
  cacheResult: true,
});
restifyAction(organizationActions.fetchTemplate, {
  key: templateId => templateId,
  cacheResult: true,
});
restifyAction(organizationActions.fetchCommunicationTemplatesFeatures, {
  key: () => '',
  cacheResult: true,
});
restifyAction(organizationActions.fetchCommunicationTemplates, {
  key: payload => `${payload.organizationId}_${payload.featureId}`,
  cacheResult: true,
});
restifyAction(subscriptionActionsV2.fetchSubscriptions, {
  key: ({ ownerId, ownerType }) => `${ownerId}_${ownerType}`,
  cacheResult: true,
});
restifyAction(userActions.fetch, { key: userId => userId, cacheResult: true });
restifyAction(userActions.fetchSystemUser, {
  key: userId => userId,
  cacheResult: false,
});
restifyAction(userActions.fetchUserRoles, { key: () => '', cacheResult: true });
restifyAction(userActions.fetchUserPrivileges, { key: () => '', cacheResult: true });
restifyAction(chargingKeyActions.fetch, {
  key: chargingKeyId => chargingKeyId,
  cacheResult: true,
});
restifyAction(chargingKeyActions.fetchByOrganization, {
  key: organizationId => organizationId,
  cacheResult: true,
});
restifyAction(chargingKeyActions.fetchByUser, {
  key: userId => userId,
  cacheResult: true,
});
restifyAction(chargingKeyActions.fetchTypes, {
  key: () => '',
  cacheResult: true,
});
restifyAction(chargingStationActions.fetchModels, {
  key: () => '',
  cacheResult: true,
});
restifyAction(chargingStationActions.fetchModel, {
  key: chargingStationModelId => chargingStationModelId,
  cacheResult: true,
});
restifyAction(chargingStationActions.fetchStatus, {
  key: chargingStationId => chargingStationId,
  cacheResult: false,
});
restifyAction(chargingStationActions.fetchNetworkStatus, {
  key: chargingStationId => chargingStationId,
  cacheResult: false,
});
restifyAction(chargingStationActions.fetchSession, {
  key: connectorId => connectorId,
  cacheResult: false,
});
restifyAction(userGroupActions.fetchUserGroup, {
  key: userGroupId => userGroupId,
  cacheResult: true,
});
restifyAction(userGroupActions.fetchUserGroups, {
  key: organizationId => organizationId,
  cacheResult: true,
});
restifyAction(mapActions.fetchMapChargePoints, { key: () => '', cacheResult: false });
restifyAction(electricVehiclesActions.fetch, {
  key: () => '',
  cacheResult: true,
});
restifyAction(invoicesActions.getNonFinalizedInvoicesByOwnerId, {
  key: ({ id, entityOwner }) => `${entityOwner}${id}`,
  cacheResult: true,
});
restifyAction(invoicesActions.hasUserAnyInvoices, {
  key: ({ ownerId }) => `hasAnyInvoices${ownerId}`,
  cacheResult: true,
});
restifyAction(invoicesActions.getInvoiceConfigurationByOrgId, {
  key: ({ orgId }) => `${orgId}`,
  cacheResult: false,
});
restifyAction(firmwareActions.fetchByModel, {
  key: firmwareModelId => firmwareModelId,
  cacheResult: false,
});
restifyAction(firmwareActions.fetchAll, {
  key: () => '',
  cacheResult: false,
});
restifyAction(schemasActions.fetchOrderSchema, {
  key: id => id,
  cacheResult: true,
});
restifyAction(schemasActions.fetchCustomerCustomFieldsSchema, {
  key: ({ organizationId, customerType }) => `${organizationId}:${customerType}`,
  cacheResult: true,
});
restifyAction(organizationOrdersActions.fetchOrganizationOrder, {
  key: id => id,
  cacheResult: false,
});
restifyAction(entitiesActions.updateEntity, {
  key: ({ schema, id }) => `-${schema.key}-${id}`,
  cacheResult: true,
});
restifyAction(organizationActions.fetchAppConfiguration, {
  key: ({ organizationId }) => organizationId,
  cacheResult: false,
});
restifyAction(organizationActions.fetchPrivateCustomerB2BConfiguration, {
  key: ({ organizationId }) => organizationId,
  cacheResult: false,
});
restifyAction(organizationActions.fetchWebPortalConfiguration, {
  key: ({ organizationId }) => organizationId,
  cacheResult: false,
});
restifyAction(organizationActions.fetchCountryCodesConfig, {
  key: organizationId => organizationId,
  cacheResult: true,
});
restifyAction(invoicesActions.hasOrganizationAnyInvoices, {
  key: ({ organizationId }) => organizationId,
  cacheResult: false,
});
restifyAction(organizationActions.fetchFleetConfig, {
  key: organizationId => organizationId,
  cacheResult: true,
});
restifyAction(firmwareActions.fetchAllErrorCodes, {
  key: () => '',
  cacheResult: true,
});

/**
 * Dispatch focus/focusLost action on `visibilitychange` event for refetching.
 *
 * By default, RTK-Query uses `focus` event to trigger refetching, but
 * this behavior generates unnecessary requests when Devtools is opened and after the user interacts with Strip input.
 */
setupListeners(store.dispatch, (dispatch, action) => {
  const handleVisibilityChange = () =>
    dispatch(window.document.visibilityState === 'visible' ? action.onFocus() : action.onFocusLost());

  window.addEventListener('visibilitychange', handleVisibilityChange, false);

  return () => {
    window.removeEventListener('visibilitychange', handleVisibilityChange);
  };
});
