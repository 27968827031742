import type { DatePickerConfigs } from '@plugsurfing/plugsurfing-design/dist/Calendar/DatePicker';
import { loadProfile } from './userProfiles';

function ensureSlash(pathname: string) {
  const hasSlash = pathname[pathname.length - 1] === '/';
  return hasSlash ? pathname : `${pathname}/`;
}
const env = window._PS_?.env || {};

export const DATE_PICKER_CONFIGS: DatePickerConfigs = {
  dateFormat: 'dd LLL yyyy',
  firstDayOfWeek: 1,
};

export const IS_DEV_MODE = env.NODE_ENV === 'development';

export const BUILD_TIMESTAMP = env.BUILD_TIMESTAMP;

export const BUILD_VERSION = env.BUILD_VERSION;

export const BUILD_COMMITHASH = env.BUILD_COMMITHASH;

export const LOG_LEVEL = env.LOG_LEVEL;

const publicUrl = new URL('/', window.location.href);

export const BASENAME = ensureSlash(publicUrl.pathname);

export const CDM_URL = env.CDM_URL;

export const LOCIZE_PROJECT_ID = env.LOCIZE_PROJECT_ID;

export const POWER_STORAGE_FACTOR = 1000;

export const MAX_DATE = '2099-12-31';

export const BOTEN_ANNA_NAME = env.BOTEN_ANNA_NAME;

export const BOTEN_ANNA_IMAGE_FILTER = env.BOTEN_ANNA_IMAGE_FILTER;

export const PRIVACY_NOTICE_URL = `https://s3-eu-west-1.amazonaws.com/prod-chargedrive-static/organizations/chargedrive-com/privacy_policy/latest.pdf`;

export const ENABLE_ENERGY_PRICE_RESTRICTION = !!+env.ENABLE_ENERGY_PRICE_RESTRICTION;

export const HERE_APP_ID = env.HERE_APP_ID;

export const HERE_APP_CODE = env.HERE_APP_CODE;

export const INVOICE_TEMPLATES = env.INVOICE_TEMPLATES;

export const ENABLE_DIRECT_PAYMENT = !!+env.ENABLE_DIRECT_PAYMENT;

export const ENABLE_SENTRY = !!+env.ENABLE_SENTRY;

export const ENABLE_DRIVE_API_ROUTE = !!+env.ENABLE_DRIVE_API_ROUTE;

export const ENABLE_POWER_SEARCH = !!+env.ENABLE_POWER_SEARCH;

export const ENABLE_VAT_OVERRIDE_CLEANUP = !!+env.ENABLE_VAT_OVERRIDE_CLEANUP;

export const ENABLE_SOFT_DELETE_LOC = !!+env.ENABLE_SOFT_DELETE_LOC;

export const ENABLE_EMP_INVOICE = !!+env.ENABLE_EMP_INVOICE;

export const ENABLE_ROAMING_PROVIDER_PRICE = !!+env.ENABLE_ROAMING_PROVIDER_PRICE;

export const SENTRY_DNS = `https://ec1587abd4ec380b3b9cc0d4fa791e67@o4506076241985536.ingest.sentry.io/4506076245721088`;

export const SENTRY_ENV = env.SENTRY_ENV;

export const STRIPE_API_KEY = env.STRIPE_API_KEY;

export const STRIPE_URL_PREFIX_PAYG = env.STRIPE_URL_PREFIX_PAYG;

export const STRIPE_URL_PREFIX_BILLING = env.STRIPE_URL_PREFIX_BILLING;

// AWS
export const S3_URL = env.S3_URL;

export const userProfile = loadProfile(env.DEFAULT_USER_PROFILE);

export const TABLE_REFRESH_AFTER_MILLIS = 2000;

export const MFA_NAME = env.MFA_NAME;
