import CdButton from 'components/design-elements/CdButton';
import CdContextMenu, { CdContextMenuItem } from 'components/design-elements/CdContextMenu';
import upperFirst from 'lodash/upperFirst';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSelf, selectUserRoles } from 'redux/users/selectors';
import { canUserAccess, type PrivilegeCheck } from 'utils/roles';
import styles from './styles.module.scss';

export type CdTableHoverActionItem = CdContextMenuItem & PrivilegeCheck;

interface CdTableHoverActionsProps {
  isSticky: boolean;
  /**
   * List of menu item or menu item group
   * The first item is rendered as a button, and the rest are hidden under the kebab menu.
   */
  menuItems: (CdTableHoverActionItem | CdTableHoverActionItem[])[];
}

export default function CdTableHoverActions({ menuItems, isSticky }: CdTableHoverActionsProps) {
  const self = useSelector(selectSelf);
  const allRoles = useSelector(selectUserRoles());
  const [head, rest] = useMemo<[CdContextMenuItem | undefined, (CdContextMenuItem | CdContextMenuItem[])[]]>(() => {
    const format = (item: CdTableHoverActionItem) => ({ ...item, text: upperFirst(item.text) });
    const canAccess = (item: CdTableHoverActionItem) =>
      canUserAccess(self, allRoles.data, item.allowedPrivileges, item.allowedModules);
    const filtered = menuItems.map(item =>
      Array.isArray(item) ? item.filter(canAccess).map(format) : canAccess(item) ? format(item) : [],
    );

    if (!isSticky) {
      return [undefined, filtered];
    }
    const [first, ...remainder] = filtered;

    if (!Array.isArray(first)) {
      return [first, remainder];
    }

    if (first.length === 0) {
      return [undefined, remainder];
    }

    return [undefined, [first, ...remainder]];
  }, [allRoles.data, isSticky, menuItems, self]);

  if (head === undefined && rest.length === 0) {
    return null;
  }

  if (!isSticky) {
    return <CdContextMenu menuItems={rest} buttonProps={{ skeletonAsIcon: true }} />;
  }

  return (
    <div className={styles.hoverActionsContainer}>
      {head && (
        <CdButton variant={head.variant ?? 'primary'} size="XS" onClick={head.onClick}>
          {head.text}
        </CdButton>
      )}
      {rest.length > 0 && <CdContextMenu menuItems={rest} buttonProps={{ skeletonAsIcon: true }} />}
    </div>
  );
}
